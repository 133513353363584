class EventManager {
    constructor() {
        this.clear();
    }

    clear() {
        this.events = {};
    };

    subscribe(name, cb) {
        if (typeof (this.events[name]) === 'undefined') {
            this.events[name] = [];
        }
        this.events[name].push(cb);
    }

    dispatch(name, param) {
        if (this.events.hasOwnProperty(name)) {
            this.events[name].forEach(function (listener) {
                listener(param);
            });
        }
    }
}

window.app.createEventManager = function () {
    return new EventManager();
};